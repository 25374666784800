<template>
  <div
    class="d-flex flex-column justify-start align-center"
    style="width: 100%"
  >
    <div
      class="d-flex flex-column justify-start align-center"
      style="width: 100%"
    >
      <div
        id="report_name"
        class="black--text mt-4"
        style="
          background-color: whitesmoke;
          width: 100%;
          text-align: center;
          max-width: 768px;
        "
      >
        {{ report_name }}
      </div>
      <!-- sterms -->
      <div
        id="terms_cont"
        class="black--text"
        v-if="sterm != null && sterm != undefined"
        style="
          background-color: whitesmoke;
          width: 100%;
          text-align: center;
          max-width: 768px;
        "
      >
        <div
          class="sterms_clr ml-2"
          style="
            font-size: 1rem;
            font-weight: 600;
            width: 100%;
            text-align: left;
          "
        >
          Search Terms
        </div>
        <!-- sterm list -->
        <div class="rowflex sterms_clr">
          <!--  STart end dates -->
          <div v-if="sterm.frm_date != '1/1/1900' && sterm.frm_date != ''">
            <div class="rowflex">
              <p class="label">From Date:</p>
              <p class="data">{{ sterm.frm_date }}</p>
              <p class="label" style="margin-left: 4px">To Date:</p>
              <p class="data">{{ sterm.frm_date }}</p>
            </div>
          </div>
          <!-- no dates so use boundry -->
          <div v-if="sterm.frm_date == '1/1/1900' || sterm.frm_date == ''">
            <div class="rowflex">
              <p class="label">From Date:</p>
              <p class="data">{{ dates_boundry.frm_date }}</p>
              <p class="label" style="margin-left: 4px">To Date:</p>
              <p class="data">{{ dates_boundry.to_date }}</p>
            </div>
          </div>
        </div>
        <div class="rowflex sterms_clr">
          <!-- location -->
          <div v-if="sterm.location != '' && sterm.location != null">
            <div class="rowflex">
              <p class="label">Location:</p>
              <p class="data">'{{ sterm.location }}'</p>
            </div>
          </div>

          <!-- email -->
          <div v-if="sterm.email != '' && sterm.email != null">
            <div class="rowflex">
              <p class="label">Created By:</p>
              <p class="data">{{ sterm.email }}</p>
            </div>
          </div>

          <!-- stop_type -->
          <div v-if="sterm.stop_type != '' && sterm.stop_type != null">
            <div class="rowflex">
              <p class="label">Stop Type:</p>
              <p class="data">{{ sterm.stop_type }}</p>
            </div>
          </div>
          <!-- stops -->
          <div v-if="sterm.cfs">
            <div class="rowflex">
              <p class="label">CFS:</p>
              <p class="data">TRUE</p>
            </div>
          </div>
          <!-- cites -->
          <div v-if="sterm.cites">
            <div class="rowflex">
              <p class="label">Cites:</p>
              <p class="data">TRUE</p>
            </div>
          </div>
          <!-- stops -->
          <div v-if="sterm.stops">
            <div class="rowflex">
              <p class="label">Stops:</p>
              <p class="data">TRUE</p>
            </div>
          </div>

          <!--  arrest -->
          <div v-if="sterm.arrest">
            <div class="rowflex">
              <p class="label">Arrest:</p>
              <p class="data">TRUE</p>
            </div>
          </div>

          <!-- dui  -->
          <div v-if="sterm.dui">
            <div class="rowflex">
              <p class="label">DUI:</p>
              <p class="data">TRUE</p>
            </div>
          </div>

          <!--  accident  -->
          <div v-if="sterm.accident">
            <div class="rowflex">
              <p class="label">Accident:</p>
              <p class="data">TRUE</p>
            </div>
          </div>

          <!--  sro  -->
          <div v-if="sterm.sro">
            <div class="rowflex">
              <p class="label">SRO:</p>
              <p class="data">TRUE</p>
            </div>
          </div>

          <!--  mait  -->
          <div v-if="sterm.mait">
            <div class="rowflex">
              <p class="label">MAIT:</p>
              <p class="data">TRUE</p>
            </div>
          </div>
          <!--  deeds  -->
          <div v-if="sterm.deeds">
            <div class="rowflex">
              <p class="label">Deeds:</p>
              <p class="data">TRUE</p>
            </div>
          </div>
          <!-- end oif search terms -->
        </div>
      </div>
      <div class="d-flex justify-center" style="width: 100%" v-if="showlines">
        <div
          style="width: 100%; max-width: 768px; background-color: whitesmoke"
        >
          <template>
            <LineChartGenerator
              :chart-options="chartOptions"
              :chart-data="chartData"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="width"
              :height="height"
            />
          </template>
        </div>
      </div>
    </div>
    <div class="d-flex justify-center" style="width: 100%">
      <v-btn
        style="max-width: 768px"
        color="primary"
        elevation="2"
        @click="backbtn"
        class="white--text mt-4"
        >Back to Results</v-btn
      >
      <v-btn
        style="max-width: 768px"
        color="primary"
        elevation="2"
        @click="print_image"
        class="white--text mt-4 ml-4"
        >Print</v-btn
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../scss/site.scss";

.sterms_clr {
  color: black;
  background-color: whitesmoke;
}
.rowflex {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-justify-content: start !important;
  justify-content: start !important;
  align-items: start !important;
  flex-wrap: wrap !important;
}

.rowflex p.label {
  margin-left: 8px;
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 0;
}

.rowflex p.data {
  font-size: 0.8rem;
  margin-left: 4px;
  color: blue;
  margin-bottom: 0;
}
</style>

<script>
import { bus_common } from "../mixins/bus_common";
import { Line as LineChartGenerator } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default {
  name: "line_cnt_by_weeks",
  components: {
    LineChartGenerator,
  },
  props: {
    chartId: {
      type: String,
      default: "line-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => {},
    },
  },
  mixins: [bus_common],
  computed: {
    sterm: function () {
      return this, this.$store.state.sterm;
    },
    dates_boundry() {
      return this.$store.state.dates_boundry;
    },
  },
  data() {
    return {
      report_name: "",
      linedata: {},
      showlines: false,
      emailuser: "",
      chartData: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            label: "Count Each Week",
            backgroundColor: "#f87979",
            data: [40, 39, 10, 40, 39, 80, 40],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  methods: {
    backbtn() {
      this.$router.push("/");
    },
    print_image() {
      var header = ` <style>
        .sterms_clr {
    color: black;
    background-color: whitesmoke;
  }
  .rowflex {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-justify-content: start !important;
    justify-content: start !important;
    align-items: start !important;
    flex-wrap: wrap !important;
  }

  .rowflex p.label {
    margin-left: 8px;
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: 0;
  }

  .rowflex p.data {
    font-size: 0.8rem;
    margin-left: 4px;
    color: blue;
    margin-bottom: 0;
  }

            .letter-header {
                width: 96%;
                font-size: 14px;
                display: -webkit-flex;
                display: flex;
                -webkit-flex-direction: row;
                flex-direction: row;
                -webkit-justify-content: space-between;
                justify-content: space-between;
                -webkit-align-items: center;
                align-items: center;
                padding: 0 2%;
                margin-bottom: 26px
            }</style>
      <div class="reportback box" style="max-width: 7.5in; height:auto; margin: auto; margin-top: 25px;">

<div class=" letter-header pt-2">
    <div class="lh-flex" style="width:2.5in">
        Reno Police Department<br /><em>"Your Police, Our Community"</em>
    </div>
    <div>
        <img style="width:1in;height:auto;" src="/images/gold-badge.png" alt="Reno nevada police Badge" />
    </div>
    <div class="lh-flex-3" style="width:2.5in;text-align:right">
        Kathryn Nance<br />
        <em>Chief of Police</em>
    </div>
</div>`;
      var canvas = document.getElementById("line-chart");
      var sterm = document.getElementById("terms_cont").outerHTML;
      var win = window.open();

      win.document.write(
        header.toString() +
          "<br><h3 style='width:100%;text-align: center;margin-bottom:0'>" +
          this.report_name +
          sterm +
          "</h3><div id='pie_cont'><img style='width:80%;margin-left:10%;margin-right:10%;' src='" +
          canvas.toDataURL() +
          "'/></div></div>"
      );
      setTimeout(function () {
        win.print();
        win.location.reload();
        win.close();
      }, 1000);
    },
    init() {
      this.show_loader(true);
      var formIn = {};
      formIn.email = this.$store.state.user.email;
      formIn.token = this.$store.state.user.token;
      console.log("Email: " + formIn.email);
      formIn.apiname = "get_line_data_by_weeks";

      var self = this;

      this.$store
        .dispatch("anyapi", formIn)
        .then((data) => {
          self.linedata = data;
          self.show_loader(false);
          if (this.linedata == null || this.linedata.length == 0) {
            this.swall_it(
              "Line Chart status",
              "The Line Chart Did not recive any data.",
              "error",
              null,
              null
            );
            return true;
          } else {
            //update pie chart
            console.log("Data recveid");
            self.report_name = "Data by Weeks";
            console.log("Counts: " + self.linedata.length);
            self.chartData.labels = [];
            self.chartData.datasets[0].data = [];

            for (let index = 0; index < self.linedata.length; index++) {
              const week = this.linedata[index];
              self.chartData.labels.push(week.weekstart);

              self.chartData.datasets[0].data.push(week.count);

              console.log(
                "show: " + self.chartData.datasets[0].data[index],
                week.weekstart
              );
            }
            self.showlines = true;
          }
        })
        .catch((msg) => {
          self.dialog = false;
          this.show_loader(false);
          this.swall_it("Error", msg, "error", null, null);
        });
    },
  },
  mounted() {
    this.show_loader(false);
    var self = this;
    this.$nextTick(() => {
      this.init();
    });
  },
};
</script>
